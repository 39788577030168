<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-lg-8 text-center">
                <div class="text-center py-5">
                    <h3>NEW</h3>
                    <h4>用木瓜酵素洗出換季時節的水潤保濕</h4>
                    <h4>Papawash加強保濕型 限定限量上市</h4>
                </div>
                <router-link to="/productlist">
                    <img src="@/assets/img/event/moisture.jpg" alt="" class="img-fluid">
                </router-link>
                <div class="text-start px-xl-3 px-xxl-6">
                    <p class="py-5">
                        極端氣候影響下，換季更容易讓肌膚變得乾燥，相對地對潤澤的需求大過日常， 因此，在選擇洗顏產品時最好選擇針對換季需求量身打造的洗顏品。
                    </p>
                    <div class="pb-5">
                        <h4>什麼才是換季洗顏的最佳選擇？</h4>
                        <p>選擇重點在於能溫和洗去多餘角質，又能在洗後保持不緊繃，最好能再添加倍保濕成份，才能讓肌膚保水潤澤。因此Papawash特別推出換季限定加強保濕版，讓您可以洗出保水好氣色，水水嫩嫩度過換季時節。</p>
                    </div>
                    <div class="pb-5">
                        <h4>添加神經醯胺越洗越保濕  洗出換季最潤澤的肌膚</h4>
                        <p>為了照顧換季時更容易出現乾燥脆弱的肌膚，特別添加珍貴的神經醯胺成份。當木瓜酵素溫和洗去角質與毛孔內髒污，在肌膚潔淨煥發的同時，神經醯胺成份能立即為潔淨的肌膚做到保水鎖水，為您帶來換季美肌的新起點，讓您越洗越水嫩，越洗越潤澤。</p>
                    </div>
                    <div class="pb-5">
                        <h4>日本原裝上市 限量限定 錯過要再等一年</h4>
                        <p>日本同步熱銷搶購中，台灣配額有限，千萬不要錯過一年一次的購買良機。</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    p{
        line-height: 1.8;
    }
    h3,h4{
        font-weight: 600;
        margin-bottom: 0.5rem;
    }
</style>
